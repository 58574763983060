<template>
  <div class="topology-view">
    <div id="service-topology"></div>
    <module-border
      title="业务列表"
      width="940px"
      height="270px"
      style="bottom: 0"
    >
      <topology-menu
        @currentTopology="item => (currentTopologyId = item.id)"
        @refresh="handleRefresh"
      ></topology-menu>
    </module-border>
  </div>
</template>

<script>
import { Graph } from '@antv/x6'
import { getService } from '@/api/service'
import ModuleBorder from '../ModuleBorder'
import TopologyMenu from './modules/TopologyMenu'

let graph = null

export default {
  name: 'TopologyView',
  components: {
    ModuleBorder,
    TopologyMenu
  },
  data () {
    return {
      currentTopologyId: '',
      topology: undefined,
      timer: null,
      topologyList: []
    }
  },
  mounted () {
    this.initGraph()
  },
  destroyed () {
    if (this.timer) clearTimeout(this.timer)
  },
  methods: {
    initGraph () {
      const container = document.getElementById('service-topology')
      graph = new Graph({
        container,
        autoResize: container,
        connecting: {
          allowBlank: false,
          allowMulti: 'withPort',
          allowLoop: false,
          allowNode: false
        },
        embedding: {
          enabled: false
        },
        mousewheel: {
          enabled: true,
          modifiers: 'ctrl',
          minScale: 0.5,
          maxScale: 4
        },
        grid: false,
        scroller: false,
        panning: true,
        interacting: false
      })
      graph.on('node:collapse', ({ node }) => {
        node.toggleCollapse()
        const collapsed = node.collapsed
        const cells = node.getDescendants()
        cells.forEach(cell => {
          if (collapsed) {
            cell.hide()
          } else {
            cell.show()
          }
        })
      })
      this.fetch()
    },
    fetch () {
      if (this.currentTopologyId) {
        const t = this.topologyList.find(v => v.id === this.currentTopologyId)
        if (t) {
          this.topology = t.data
          graph.fromJSON({ cells: this.topology })
          graph.centerContent()
        } else {
          getService(this.currentTopologyId)
            .then(res => {
              this.topology = res.data.topology
              this.topology.forEach(item => {
                if (item.shape === 'edge') {
                  item.attrs.line.stroke = '#999'
                  item.attrs.line.strokeWidth = 1
                  item.attrs.line.targetMarker = null
                }
                if (item.shape === 'group') {
                  item.attrs.button.fill = '#009CE2'
                }
                if (item.shape === 'vue-shape') {
                  item.size = { height: 48, width: 48 }
                  if (Array.isArray(item.ports)) {
                    const ports = item.ports
                    item.ports = {
                      groups: {
                        default: {
                          markup: [
                            {
                              tagName: 'rect',
                              selector: 'rect'
                            }
                          ],
                          attrs: {
                            rect: {
                              magnet: true,
                              stroke: '#31d0c6',
                              fill: 'rgba(255,255,255,0)',
                              strokeWidth: 0,
                              width: 48,
                              height: 48,
                              y: -24
                            }
                          },
                          zIndex: -99
                        }
                      },
                      items: []
                    }

                    ports.forEach(port => {
                      port.group = 'default'
                    })
                    item.ports.items = ports
                  }
                }
              })
              graph.fromJSON({ cells: this.topology })
              graph.centerContent()
              this.topologyList.push({
                id: this.currentTopologyId,
                data: this.topology
              })
            }).catch(() => {})
        }
      }
    },
    handleRefresh () {
      this.topologyList = []
    }
  },
  watch: {
    currentTopologyId () {
      this.fetch()
    }
  }
}
</script>

<style lang="less">
.topology-view {
  position: absolute;
  box-sizing: border-box;
  height: 972px;
  width: 940px;

  #service-topology {
    position: absolute;
    width: 940px;
    height: 712px;
    bottom: 280px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px #092e6a33, inset 0px 0px 20px #08427542;

    .service-node-name {
      color: rgba(255,255,255,.85) !important;
    }
    .x6-node {
      text {
        .v-line {
          fill: #cde7ff;
        }
      }
    }
  }
  @keyframes topology-edge {
    to {
      stroke-dashoffset: -1000;
    }
  }
}
</style>
