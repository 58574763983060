<template>
  <div id="alert-trend-chart" style="width: 450px; height: 224px"></div>
</template>

<script>
import moment from 'moment'
import { getAlertTrend } from '@/api/alert'

let chart = null

export default {
  name: 'AlertTrend',
  data () {
    return {
      chartData: [],
      chartDate: [],
      timer: null
    }
  },
  mounted () {
    this.initChart()
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (chart) {
      chart.clear()
      chart = null
    }
  },
  methods: {
    initChart () {
      if (chart !== null) chart.dispose()
      chart = this.$echarts.init(document.getElementById('alert-trend-chart'))

      const severity = ['disaster', 'high', 'average', 'warning']
      const severityName = ['紧急', '严重', '普通', '预警']
      const values = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0]
      ]
      let now = moment().format('YYYY-MM-DD')
      for (let i = 0; i < 7; i++) {
        this.chartDate.push(now)
        now = moment(now).subtract(1, 'day').format('YYYY-MM-DD')
      }
      this.chartDate.reverse()
      this.chartDate.forEach((ele, i) => {
        this.chartDate[i] = this.formatDate(ele)
      })

      var color = [
        'rgba(255,91,115',
        'rgba(250,173,20',
        'rgba(9,109,217',
        'rgba(56,211,250'
      ]
      var lineY = []
      for (var i = 0; i < severity.length; i++) {
        var data = {
          name: severityName[i],
          type: 'line',
          color: color[i] + ')',
          smooth: true,
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: color[i] + ', 0.3)'
                },
                {
                  offset: 0.8,
                  color: color[i] + ', 0)'
                }
              ],
              false
            ),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
          },
          symbol: 'circle',
          symbolSize: 5,
          data: values[i]
        }
        lineY.push(data)
      }

      var option = {
        animationDuration: 600,
        animationDurationUpdate: 600,
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: severityName,
          textStyle: {
            fontSize: 12,
            color: '#aabed7'
          },
          right: '95',
          top: '10'
        },
        grid: {
          top: '45',
          left: '25',
          right: '25',
          bottom: '10',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.chartDate,
          splitLine: {
            lineStyle: {
              color: '#113763'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#113763'
            }
          },
          axisLabel: {
            interval: 0,
            color: '#aabed7'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            color: '#aabed7'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#113763'
            }
          },
          axisLine: {
            show: false
          }
        },
        series: lineY
      }
      chart.setOption(option)
      this.fetch()
    },
    fetch () {
      chart.showLoading({
        text: '加载中...',
        maskColor: '#051733',
        zlevel: 999,
        color: '#314daa',
        textColor: '#a3b7d9'
      })
      getAlertTrend({
        trend_of: 'severity',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm')
      }).then(res => {
        this.chartData = res.data.data
        const series = []
        const severity = ['disaster', 'high', 'average', 'warning']
        const values = [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0]
        ]
        if (this.chartData.length) {
          for (let i = 0; i < this.chartDate.length; i++) {
            const item = this.chartDate[i]
            const arr = this.chartData.filter(
              ele => this.formatDate(ele.date) === item
            )
            severity.forEach((e, index) => {
              const count = arr.find(e1 => e1.name === e)
              if (count !== undefined) {
                values[index][i] = count.value
              }
            })
          }
        }
        for (let i = 0; i < severity.length; i++) {
          series.push({ data: values[i] })
        }
        chart.setOption({ series })
      }).finally(() => {
        chart.hideLoading()
        this.timer = setTimeout(() => {
          this.fetch()
        }, 1000 * 60 * 5)
      })
    },
    formatDate (date) {
      const arr = date.split('-')
      return arr[1] + '.' + arr[2]
    }
  }
}
</script>
