<template>
  <div class="asset-count" @click="goAssetDetail">
    <a-row class="asset-count__header">
      <div class="header__border"></div>
      <a-tooltip :title="countData.name">
        <h3 class="header__title">{{ countData.name }}</h3>
      </a-tooltip>
    </a-row>
    <p class="asset-count__content">{{ countData.value }}</p>
    <div class="asset-count__bottom"></div>
  </div>
</template>

<script>
export default {
  name: 'AssetCount',
  props: {
    countData: {
      type: Object,
      default: () => {
        return {
          name: '',
          value: 0,
          routerName: ''
        }
      }
    }
  },
  methods: {
    goAssetDetail () {
      if (this.countData.routerName) this.$router.push({ name: this.countData.routerName })
    }
  }
}
</script>

<style lang="less">
.asset-count {
  padding: 6px 0;
  width: 80px;
  min-width: 80px;
  margin-right: 6px;
  cursor: pointer;

  .asset-count__header {
    display: flex;
    padding-left: 12px;

    .header__border {
      width: 3px;
      height: 8px;
      min-width: 3px;
      min-height: 8px;
      background-color: #ff9c66;
      margin-top: 4px;
    }
    .header__title {
      color: #a3b7d9;
      font-size: 12px;
      font-weight: 400;
      padding-left: 5px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .asset-count__content {
    color: #cadfff;
    margin-right: 20px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 600;
    text-align: right;
    margin-top: 10px;
  }

  .asset-count__bottom {
    position: relative;
    border-bottom: 1.5px solid #4974cd80;
    margin: 4px 9px 0;
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: -2.5px;
      right: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #4974cd;
    }
  }
}
</style>
