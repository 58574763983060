import { render, staticRenderFns } from "./ModuleBorder.vue?vue&type=template&id=1e6ec566"
import script from "./ModuleBorder.vue?vue&type=script&lang=js"
export * from "./ModuleBorder.vue?vue&type=script&lang=js"
import style0 from "./ModuleBorder.vue?vue&type=style&index=0&id=1e6ec566&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports