<template>
  <div class="alert-now">
    <a-row :gutter="8">
      <a-col :span="8" class="alert-now__statistics-container">
        <a-row class="alert-now__statistics-container__header">
          <div class="header__border"></div>
          <div class="header__border"></div>
          <h3 class="header__title">当前告警</h3>
        </a-row>
        <a-tooltip :title="alertNow">
          <p
            class="alert-now__statistics-container__content content--now"
            @click="
              $router.push({
                name: 'AlertHistory',
                params: { recovered: 'false' }
              })
            "
          >
            {{ alertNow > 9999 ? '9999+' : alertNow }}
          </p>
        </a-tooltip>
        <div class="alert-now__statistics-container__bottom"></div>
      </a-col>
      <a-col :span="8" style="text-align: center">
        <a-progress
          type="circle"
          stroke-linecap="square"
          :percent="recoveredRate"
          :width="120"
          strokeColor="#3fe4ff"
          style="margin-top: 34px"
        >
          <template #format="percent">
            <p style="color: #fff; margin-bottom: 8px; font-size: 14px">
              处理率
            </p>
            <p style="color: #3fe4ff; font-size: 26px">{{ percent }}%</p>
          </template>
        </a-progress>
      </a-col>
      <a-col :span="8" class="alert-now__statistics-container">
        <a-row class="alert-now__statistics-container__header">
          <div class="header__border"></div>
          <div class="header__border"></div>
          <h3 class="header__title">总告警</h3>
        </a-row>
        <a-tooltip :title="alertTotal">
          <p
            class="alert-now__statistics-container__content"
            @click="$router.push({ name: 'AlertHistory' })"
          >
            {{ alertTotal > 9999 ? '9999+' : alertTotal }}
          </p>
        </a-tooltip>
        <div class="alert-now__statistics-container__bottom"></div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getAlertList } from '@/api/alert'

export default {
  name: 'AlertNow',
  data () {
    return {
      params: {
        page: 1,
        page_size: 10
      },
      alertTotal: 0,
      alertNow: 0,
      recoveredRate: 0,
      timer: null
    }
  },
  mounted () {
    this.fetch()
  },
  destroyed () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    fetch () {
      const p1 = getAlertList(this.params).then(res => {
        this.alertTotal = res.data.total
      }).catch(() => {})
      const p2 = getAlertList({ ...this.params, recovered: false }).then(res => {
        this.alertNow = res.data.total
      }).catch(() => {})
      Promise.all([p1, p2]).finally(() => {
        const v = this.alertTotal - this.alertNow
        if (this.alertTotal > 0 && v > 0) {
          this.recoveredRate =
            parseInt((v / this.alertTotal) * 1000) /
            10
        } else this.recoveredRate = 0
        this.timer = setTimeout(() => {
          this.fetch()
        }, 1000 * 60 * 5)
      })
    }
  }
}
</script>

<style lang="less">
.alert-now {
  padding: 0 16px;

  .ant-progress-circle-trail {
    stroke: rgb(18 61 94) !important;
  }

  .alert-now__statistics-container {
    margin-top: 32px;
    .alert-now__statistics-container__header {
      display: flex;
      margin: 8px 0 26px 18px;
      .header__border {
        width: 4px;
        height: 10px;
        background-color: #3fe4ff;
        transform: skew(-25deg);
        margin-right: 5px;
        margin-top: 5px;
      }
      .header__title {
        color: rgba(255, 255, 255, 0.9);
        font-size: 14px;
        font-weight: 400;
        margin-left: 6px;
        line-height: 20px;
      }
    }
    .alert-now__statistics-container__content {
      margin-right: 32px;
      font-size: 32px;
      font-family: Verdana;
      text-align: right;
      position: relative;
      cursor: pointer;
      // color:#24f0ae;
     color: #ff6461;
      font-weight: 600;

      &::after {
        content: '条';
        display: inline-block;
        position: absolute;
        right: -20px;
        top: 0;
        font-size: 12px;
        font-family: 'PingFang', sans-serif;
      }
    }
    .content--now {

      color: #ff9e5a;
    }
    .alert-now__statistics-container__bottom {
      position: relative;
      margin: 12px 8px 0;
      border-bottom: 2px solid #3fe5ff76;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -2.5px;
        right: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #3fe4ff;
      }
    }
  }
}
</style>
