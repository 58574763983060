<template>
  <div class="container__module" :style="style">
    <div class="module__header">
      <p class="module__header__title">{{ title }}</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ModuleBorder',
  props: {
    width: {
      type: String,
      default: '450px'
    },
    height: {
      type: String,
      default: '450px'
    },
    title: {
      type: String
    }
  },
  computed: {
    style () {
      const style = {
        height: this.height,
        width: this.width
      }
      return style
    }
  }
}
</script>

<style lang="less">
.container__module {
  position: absolute;
  box-sizing: border-box;
  border-top: 1px solid #3fbee8;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px #1c73ff33, inset 0px 0px 30px #3fbee822;
  border-radius: 4px;

  .module__header {
    height: 40px;
    background: linear-gradient(90deg, #2372b610, #2874ca90, #2371b610);
    border-radius: 4px;
  }

  .module__header__title {
    position: relative;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    line-height: 40px;
    font-family: YouSheBiaoTiHei;
    color: #e1e9f5;
    text-shadow: 0px 2px 8px rgba(5,28,55,0.42);
    background: linear-gradient(0deg, rgba(14,197,236,0.36) 0%, rgba(49,190,255,0.36) 0%, rgba(239,252,254,0.36) 58.7646484375%);
    -webkit-background-clip: text;
    opacity: 0.85;
    letter-spacing: 2px;
    // -webkit-text-fill-color: transparent;

    // text-shadow: ;

    &::before {
      content: '';
      position: absolute;
      left: 16px;
      top: 11px;
      display: block;
      height: 18px;
      width: 5px;
      background: #04b1fe;
      box-shadow: 0 0 8px #93d9ff;
    }
    &::after {
      content: '';
      position: absolute;
      right: 16px;
      top: 11px;
      display: block;
      height: 18px;
      width: 5px;
      background: #04b1fe;
      box-shadow: 0 0 8px #93d9ff;
    }
  }
}
</style>
