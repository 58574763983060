<template>
  <div class="assets-statistics">
    <div class="assets-statistics__asset assets-statistics__host">
      <a-row>
        <a-col :span="5" class="asset__left">
          <source-icon
            class="asset__left__icon"
            source-type="server"
          ></source-icon>
          <p class="asset__left__title">IT 资源</p>
        </a-col>
        <a-col :span="19" class="asset__right">
          <asset-count
            v-for="item in itSource"
            :key="item.name"
            :countData="item"
          ></asset-count>
        </a-col>
      </a-row>
    </div>
    <div class="assets-statistics__asset assets-statistics__system">
      <a-row>
        <a-col :span="5" class="asset__left">
          <source-icon class="asset__left__icon" source-type="os"></source-icon>
          <p class="asset__left__title">操作系统</p>
        </a-col>
        <a-col :span="19" class="asset__right">
          <asset-count
            v-for="item in systemType"
            :key="item.name"
            :countData="item"
          ></asset-count>
        </a-col>
      </a-row>
    </div>
    <div class="assets-statistics__asset assets-statistics__middleware">
      <a-row>
        <a-col :span="5" class="asset__left">
          <source-icon
            class="asset__left__icon"
            source-type="middleware"
          ></source-icon>
          <p class="asset__left__title">中间件</p>
        </a-col>
        <a-col :span="19" class="asset__right">
          <asset-count
            v-for="item in middlewareType"
            :key="item.name"
            :countData="item"
          ></asset-count>
        </a-col>
      </a-row>
    </div>
    <div class="assets-statistics__asset assets-statistics__database">
      <a-row>
        <a-col :span="5" class="asset__left">
          <source-icon
            class="asset__left__icon"
            source-type="database"
          ></source-icon>
          <p class="asset__left__title">数据库</p>
        </a-col>
        <a-col :span="19" class="asset__right">
          <asset-count
            v-for="item in databaseType"
            :key="item.name"
            :countData="item"
          ></asset-count>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import SourceIcon from '@/components/icon/SourceIcon'
import AssetCount from './module/AssetCount.vue'
import { getMiddlewareCount } from '@/api/middleware'
import { getDatabaseCount } from '@/api/database'
import { getOSCount } from '@/api/os'
import { getServerList } from '@/api/server'
import { getNetworkDeviceList } from '@/api/network-device'
import { getStorageList } from '@/api/storage'

export default {
  name: 'AssetsStatistics',
  components: {
    SourceIcon,
    AssetCount
  },
  data () {
    return {
      middlewareType: [],
      databaseType: [],
      hostType: [],
      systemType: [],
      itSource: [
        { name: '网络设备', value: 0, routerName: null },
        { name: '存储', value: 0, routerName: 'Storage' },
        { name: '物理机', value: 0, routerName: 'Server' }
      ],
      timer: null
    }
  },
  mounted () {
    this.fetch()
  },
  destroyed () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    fetch () {
      this.middlewareType = []
      this.databaseType = []
      this.hostType = []
      this.systemType = []
      const p1 = getMiddlewareCount({ count_of: 'middleware_type' }).then(res => {
        res.data.data.map(item => {
          const m = this.middlewareType.find(v => v.name === this.$t(`middleware_type.${item.name}`))
          if (m) m.value = item.value
          else {
            this.middlewareType.push({
              name: this.$t(`middleware_type.${item.name}`),
              value: item.value,
              routerName: 'Middleware'
            })
          }
        })
      }).catch(() => {})
      const p2 = getDatabaseCount({ count_of: 'database_type' }).then(res => {
        res.data.data.map(item => {
          const d = this.databaseType.find(v => v.name === this.$t(`database_type.${item.name}`))
          if (d) d.value = item.value
          else {
            this.databaseType.push({
              name: this.$t(`database_type.${item.name}`),
              value: item.value,
              routerName: 'Database'
            })
          }
        })
      }).catch(() => {})
      const p3 = getOSCount({ count_of: 'os_type' }).then(res => {
        res.data.data.map(item => {
          const s = this.systemType.find(v => v.name === this.$t(`os_type.${item.name}`))
          if (s) s.value = item.value
          else {
            this.systemType.push({
              name: this.$t(`os_type.${item.name}`),
              value: item.value,
              routerName: 'OS'
            })
          }
        })
      }).catch(() => {})
      const p4 = getNetworkDeviceList().then(res => {
        this.itSource[0].value = res.data.total
      }).catch(() => {})
      const p5 = getStorageList().then(res => {
        this.itSource[1].value = res.data.total
      }).catch(() => {})
      const p6 = getServerList().then(res => {
        this.itSource[2].value = res.data.total
      }).catch(() => {})
      Promise.all([p1, p2, p3, p4, p5, p6]).finally(() => {
        this.timer = setTimeout(() => {
          this.fetch()
        }, 1000 * 60 * 5)
      })
    }
  }
}
</script>

<style lang="less">
.assets-statistics {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  height: 400px;

  .assets-statistics__asset {
    box-sizing: border-box;
    height: 90px;
    width: 100%;
    box-shadow: 0px 0px 5px #092e6a1a, inset 0px 0px 20px #00529627;
    padding: 8px;
    border-radius: 4px;

    .asset__left {
      text-align: center;
      height: 76px;
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        height: 64px;
        width: 1px;
        border-left: 1px solid #3461be;
        right: 0;
        top: 5px;
      }

      .asset__left__icon {
        font-size: 44px;
        color: #129bff;
        margin: 4px 0;
      }

      .asset__left__title {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
      }
    }

    .asset__right {
      height: 90px;
      display: flex;
      padding-left: 8px;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none !important; //火狐
      -ms-overflow-style: none !important; //IE 10+
      &::-webkit-scrollbar {
        width: 0 !important; //Chrome Safari
      }
    }
  }
}
</style>
