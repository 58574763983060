<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'PlayIcon',
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M514 114.3c-219.9 0-398.9 178.9-398.9 398.9 0.1 219.9 179 398.8 398.9 398.8 219.9 0 398.8-178.9 398.8-398.8S733.9 114.3 514 114.3z m173 421.9L437.1 680.5c-17.7 10.2-39.8-2.6-39.8-23V368.9c0-20.4 22.1-33.2 39.8-23L687 490.2c17.7 10.2 17.7 35.8 0 46z" fill="currentColor" p-id="1301"></path>
          </svg>
        `
      }
    }
  }
}
</script>
