<template>
  <div
    ref="bigScreenContainer"
    :style="{
      height: isFull ? '100vh' : 'calc(100vh - 142px)',
      width: isFull ? '100vw' : 'calc(100vw - 224px)',
      position: 'relative'
    }"
  >
    <div class="big-screen" ref="bigScreen">
      <div class="big-screen__container">
        <div class="big-screen__container__header">
          <h1 class="overflow-ellipsis not-select">OpsEye 信息化智能监控平台</h1>
        </div>
      </div>

      <div class="home">
        <a @click="handleFullScreen">
          <template v-if="!isFull">
            <a-icon :component="fullIcon" style="font-size: 16px;"/>
            <span style="font-size: 14px; vertical-align: text-top; margin-left: 4px;">全屏展示</span>
          </template>
          <template v-else>
            <a-icon :component="notFullIcon"  style="font-size: 16px;"/>
            <span style="font-size: 14px; vertical-align: text-top; margin-left: 4px;">退出全屏</span>
          </template>
        </a>
      </div>

      <module-border
        style="top: 72px; left: 24px"
        height="432px"
        title="资产统计"
      >
        <assets-statistics style="padding: 8px 0px"></assets-statistics>
      </module-border>

      <module-border
        style="top: 514px; left: 24px"
        height="270px"
        title="CPU使用率"
      >
        <cpu-usage></cpu-usage>
      </module-border>

      <module-border
        style="top: 794px; left: 24px"
        height="270px"
        title="内存使用率"
      >
        <memory-usage></memory-usage>
      </module-border>

      <status-question-icon
        :is-service-topology="true"
        :is-in-table="false"
        class="topology-tooltip"
        style="position: absolute; left: 1008px; top: 82px; z-index: 9999;"
      ></status-question-icon>
      <topology-view style="top: 92px; left: 490px"></topology-view>

      <module-border
        style="top: 72px; right: 24px"
        height="220px"
        title="当前告警"
      >
        <alert-now></alert-now>
      </module-border>

      <module-border
        style="top: 302px; right: 24px"
        height="482px"
        title="累计告警"
      >
        <alert-total></alert-total>
      </module-border>

      <module-border
        style="top: 794px; right: 24px"
        height="270px"
        title="近7日告警趋势"
      >
        <alert-trend></alert-trend>
      </module-border>
    </div>
  </div>
</template>

<script>
import '@/fonts/font.css'
import AssetsStatistics from './modules/AssetsStatistics'
import ModuleBorder from './modules/ModuleBorder'
import TopologyView from './modules/TopologyView'
import AlertNow from './modules/AlertNow'
import AlertTotal from './modules/AlertTotal'
import AlertTrend from './modules/AlertTrend'
import CpuUsage from './modules/CpuUsage'
import MemoryUsage from './modules/MemoryUsage'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'

export default {
  name: 'BigScreen',
  components: {
    AssetsStatistics,
    ModuleBorder,
    TopologyView,
    AlertNow,
    AlertTotal,
    AlertTrend,
    CpuUsage,
    MemoryUsage,
    StatusQuestionIcon
  },
  data () {
    return {
      designWidth: 1920,
      designHeight: 1080,
      screenBox: null,
      screenContainer: null,
      clientWidth: 0,
      clientHeight: 0,
      isFull: false,
      fullIcon: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M892.038 182.873l-708.65 708.65h190.333c18.99 0 34.384 15.447 34.384 34.5 0 19.054-15.394 34.5-34.384 34.5H98.644c-18.99 0-34.385-15.446-34.385-34.5v-276c0-9.15 3.623-17.925 10.071-24.395a34.326 34.326 0 0 1 24.314-10.105c9.119 0 17.865 3.635 24.313 10.105a34.559 34.559 0 0 1 10.071 24.395V840.06l706.425-706.425H651.346c-18.99 0-34.384-15.446-34.384-34.5 0-19.054 15.394-34.5 34.384-34.5h275.077c18.99 0 34.385 15.446 34.385 34.5v276c0 9.15-3.623 17.925-10.071 24.395a34.326 34.326 0 0 1-24.314 10.105 34.326 34.326 0 0 1-24.314-10.105 34.559 34.559 0 0 1-10.07-24.395V182.873zM99.385 410a34.326 34.326 0 0 1-24.314-10.105A34.559 34.559 0 0 1 65 375.5v-276C65 80.446 80.395 65 99.385 65h275.077c18.99 0 34.384 15.446 34.384 34.5 0 19.054-15.394 34.5-34.384 34.5H133.769v241.5c0 9.15-3.622 17.925-10.07 24.395A34.326 34.326 0 0 1 99.384 410z m825.23 552H649.538c-18.99 0-34.384-15.446-34.384-34.5 0-19.054 15.394-34.5 34.384-34.5h240.693V651.5c0-19.054 15.394-34.5 34.384-34.5 18.99 0 34.385 15.446 34.385 34.5v276c0 19.054-15.395 34.5-34.385 34.5z" fill="currentColor" p-id="2086"></path>
          </svg>
        `
      },
      notFullIcon: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M379.336 697.237L153.362 921.55c-14.11 14.007-36.905 13.922-50.912-0.188-14.007-14.11-13.922-36.905 0.188-50.912l227.6-225.927H138.645c-18.99 0-34.385-15.446-34.385-34.5 0-19.053 15.395-34.5 34.385-34.5H413.72c18.99 0 34.384 15.447 34.384 34.5v276c0 9.15-3.622 17.926-10.07 24.396a34.326 34.326 0 0 1-24.314 10.104 34.326 34.326 0 0 1-24.314-10.104 34.559 34.559 0 0 1-10.071-24.396V697.237z m263.395-366.88l227.813-227.813c14.059-14.059 36.853-14.059 50.912 0 14.059 14.059 14.059 36.853 0 50.912l-225.18 225.18h187.147c18.99 0 34.385 15.445 34.385 34.5 0 19.053-15.395 34.5-34.385 34.5H608.346c-18.99 0-34.384-15.447-34.384-34.5v-276c0-9.15 3.622-17.926 10.07-24.396a34.326 34.326 0 0 1 24.314-10.105c9.12 0 17.865 3.635 24.314 10.105a34.559 34.559 0 0 1 10.07 24.395v193.223zM99.385 410a34.326 34.326 0 0 1-24.314-10.105A34.559 34.559 0 0 1 65 375.5v-276C65 80.446 80.395 65 99.385 65h275.077c18.99 0 34.384 15.446 34.384 34.5 0 19.054-15.394 34.5-34.384 34.5H133.769v241.5c0 9.15-3.622 17.925-10.07 24.395A34.326 34.326 0 0 1 99.384 410z m825.23 552H649.538c-18.99 0-34.384-15.446-34.384-34.5 0-19.054 15.394-34.5 34.384-34.5h240.693V651.5c0-19.054 15.394-34.5 34.384-34.5 18.99 0 34.385 15.446 34.385 34.5v276c0 19.054-15.395 34.5-34.385 34.5z" fill="currentColor" p-id="2240"></path>
          </svg>
        `
      }
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    document.addEventListener('fullscreenchange', this.onFullScreenChange)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
    document.removeEventListener('fullscreenchange', this.onFullScreenChange)
  },
  methods: {
    onResize () {
      let scaleW = 0
      let scaleH = 0
      this.screenBox = this.$refs.bigScreen
      this.screenContainer = this.$refs.bigScreenContainer

      this.clientWidth = this.screenContainer.clientWidth
      this.clientHeight = this.screenContainer.clientHeight
      scaleW = this.clientWidth / this.designWidth
      scaleH = this.clientHeight / this.designHeight
      this.screenBox.style.transform = `scale(${scaleW},${scaleH})`
    },
    handleFullScreen () {
      const ele = document.fullscreenElement
      if (ele) {
        document.exitFullscreen()
      } else {
        this.screenContainer.requestFullscreen()
      }
      this.isFull = !document.fullscreenElement
    },
    onFullScreenChange () {
      this.isFull = Boolean(document.fullscreenElement)
      this.$nextTick(() => {
        this.onResize()
      })
    }
  }
}
</script>

<style lang="less">
.big-screen {
  // 全局单行文本省略
  .overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  // 禁止文字选中
  .not-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  p {
    margin: 0;
  }
  width: 1920px;
  height: 1080px;
  transform-origin: top left;
  color: #ffffff;
  font-family: 'PingFang', sans-serif;
  background-image: url('../../assets/images/network-screen/screen-bg.png');
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .big-screen__container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 5, 57, 0.1);

    .big-screen__container__header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1900px;
      height: 86px;
      position: absolute;
      left: 10px;
      top: 0;
      background: url('../../assets/images/big-screen-header.png') no-repeat;
      background-size: auto;
      background-position-y: -30px;

      h1 {
        font-size: 32px;
        font-family: YouSheBiaoTiHei;
        color: #eff8fc;
        line-height: 42px;
        opacity: 0.89;
        text-shadow: 0px 4px 1px rgba(19, 80, 143, 0.66);
        letter-spacing: 2px;
        background: linear-gradient(
          0deg,
          rgba(119, 186, 255, 0.45) 0%,
          rgba(233, 248, 255, 0.45) 73.3154296875%,
          rgba(255, 255, 255, 0.45) 100%
        );
        -webkit-background-clip: text;
      }
    }
  }

  .home {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  .topology-tooltip {
    .text {
      color: rgba(255, 255, 255, 0.85) !important;
    }

    .question-icon {
      color: rgba(255, 255, 255, 0.65) !important;
      opacity: 1;

      &:hover {
        color: #1574DA !important;
        opacity: 1;
      }
    }
  }
}
</style>
