<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'PauseIcon',
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M514 114.3c-219.9 0-398.9 178.9-398.9 398.8 0.1 220 179 398.9 398.9 398.9 219.9 0 398.8-178.9 398.8-398.9 0-219.8-178.9-398.8-398.8-398.8z m-35.6 531.5c0 32.5-26.3 58.8-58.8 58.8s-58.8-26.3-58.8-58.8V381.4c0-32.5 26.3-58.8 58.8-58.8s58.8 26.3 58.8 58.8v264.4z m188.7 0c0 32.5-26.3 58.8-58.8 58.8s-58.8-26.3-58.8-58.8V381.4c0-32.5 26.3-58.8 58.8-58.8s58.8 26.3 58.8 58.8v264.4z" fill="currentColor" p-id="24003"></path>
          </svg>
        `
      }
    }
  }
}
</script>
