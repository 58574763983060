<template>
  <div>
    <div id="alert-total-severity-chart"></div>
    <div id="alert-total-source-chart"></div>
  </div>
</template>

<script>
import 'echarts-liquidfill'
import { alertSeverities, sourceTypes } from '@/utils/const'
import { getAlertList } from '@/api/alert'

let severityChart = null
let sourceChart = null

export default {
  name: 'AlertTotal',
  data () {
    return {
      data: [
        [0.6, 0.55, 0.5],
        [0.6, 0.55, 0.5],
        [0.6, 0.55, 0.5],
        [0.6, 0.55, 0.5]
      ],
      color: ['#FF5B73', '#FAAD14', '#096DD9', '#38D3FA'],
      severityName: ['紧急', '严重', '普通', '预警'],
      sourceTypeData: [],
      severityTimer: null,
      sourceTimer: null
    }
  },
  mounted () {
    this.initSeverityChart()
    this.initSourceChart()
  },
  beforeDestroy () {
    if (this.sourceTimer) {
      clearTimeout(this.sourceTimer)
      this.sourceTimer = null
    }
    if (this.severityTimer) {
      clearTimeout(this.severityTimer)
      this.severityTimer = null
    }
    if (severityChart) {
      severityChart.clear()
      severityChart = null
    }
    if (sourceChart) {
      sourceChart.clear()
      sourceChart = null
    }
  },
  methods: {
    fetch (params) {
      return getAlertList(params)
    },
    initSeverityChart () {
      if (severityChart !== null) severityChart.dispose()
      severityChart = this.$echarts.init(
        document.getElementById('alert-total-severity-chart')
      )
      const graphic = []
      const series = []
      let left = 50
      let left1 = 60
      for (let i = 0; i < alertSeverities.length; i++) {
        const obj = {
          type: 'group',
          left,
          top: '85',
          children: [
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: '25',
              style: {
                fill: 'rgba(255,255,255,1)',
                text: '(条)',
                fontSize: 10
              }
            },
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: '60',
              style: {
                fill: this.color[i],
                text: this.severityName[i],
                fontSize: 12
              }
            }
          ]
        }
        left = left + 100
        graphic.push(obj)
        const obj1 = {
          type: 'liquidFill',
          center: [left1, '65'],
          color: [this.color[i]],
          radius: '55%',
          animationDuration: 600,
          animationDurationUpdate: 600,
          data: this.data[i],
          label: {
            fontSize: 20,
            formatter: () => {
              return 0
            }
          },
          outline: {
            borderDistance: 3,
            itemStyle: {
              borderWidth: 5,
              borderColor: this.color[i]
            }
          },
          backgroundStyle: {
            color: 'rgba(0,0,0,0)'
          }
        }
        left1 = left1 + 100
        series.push(obj1)
      }
      severityChart.setOption({ graphic, series })
      this.fetchSeverity()
      severityChart.on('click', param => {
        if (param.componentType === 'series') {
          const params = { severity: alertSeverities[param.componentIndex] }
          this.$router.push({ name: 'AlertHistory', params })
        }
      })
    },
    fetchSeverity () {
      severityChart.showLoading({
        text: '加载中...',
        maskColor: '#051733',
        zlevel: 999,
        color: '#314daa',
        textColor: '#a3b7d9'
      })
      Promise.all(alertSeverities.map(severity => new Promise(resolve => {
        let v
        this.fetch({
          page_size: 10,
          page: 1,
          severity
        }).then(res => {
          v = {
            label: {
              fontSize: res.data.total >= 9999 ? 16 : 20,
              formatter: () => res.data.total
            }
          }
        }).catch(() => {
          v = {
            label: {
              fontSize: 20,
              formatter: () => 0
            }
          }
        }).finally(() => {
          resolve(v)
        })
      }))).then(res => {
        severityChart.setOption({ series: res })
      }).finally(() => {
        severityChart.hideLoading()
        this.severityTimer = setTimeout(() => {
          this.fetchSourceType()
        }, 1000 * 60 * 5)
      })
    },
    initSourceChart () {
      if (sourceChart !== null) sourceChart.dispose()
      sourceChart = this.$echarts.init(
        document.getElementById('alert-total-source-chart')
      )
      const xLabel = []
      sourceTypes.forEach(e => {
        xLabel.push(this.$t(`source_type.${e}`))
      })
      const option = {
        tooltip: {},
        animation: true,
        animationDuration: 600,
        animationDurationUpdate: 600,
        grid: {
          top: '20',
          bottom: '54',
          right: '20',
          left: '55'
        },
        xAxis: {
          data: xLabel,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#11417a'
            }
          },
          axisTick: 'none',
          axisLabel: {
            show: true,
            interval: 0,
            fontSize: 12,
            color: '#aabed7',
            rotate: 45
          },
          triggerEvent: true
        },
        yAxis: [
          {
            type: 'value',
            gridIndex: 0,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#113763',
                width: 1
              }
            },
            axisTick: 'none',
            axisLine: 'none',
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 12,
              color: '#aabed7'
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(36,188,255,1)'
                },
                {
                  offset: 1,
                  color: '#034881'
                }
              ])
            },
            data: [],
            z: 10,
            zlevel: 0,
            label: {
              show: true,
              position: 'top',
              distance: 5,
              fontSize: 14,
              color: '#83c9fe'
            }
          },
          {
            type: 'pictorialBar',
            itemStyle: {
              color: '#0F375F'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 6,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: 'start',
            symbolOffset: [0, -1],
            data: [],
            width: 25,
            z: 0,
            zlevel: 1
          }
        ]
      }
      sourceChart.setOption(option)
      this.fetchSourceType()
      sourceChart.on('click', param => {
        const params = { source_type: sourceTypes[param.dataIndex] }
        this.$router.push({ name: 'AlertHistory', params })
      })
    },
    fetchSourceType () {
      this.sourceTypeData = []
      sourceChart.showLoading({
        text: '加载中...',
        maskColor: '#051733',
        zlevel: 999,
        color: '#314daa',
        textColor: '#a3b7d9'
      })

      Promise.all(sourceTypes.map(sourceType => new Promise((resolve) => {
        let value
        this.fetch({
          page_size: 10,
          page: 1,
          source_type: sourceType
        }).then(res => {
          value = res.data.total
        }).catch(() => {
          value = 0
        }).finally(() => {
          resolve(value)
        })
      }))).then(res => {
        sourceChart.setOption({
          series: [{ data: res }, { data: res }]
        })
      }).finally(() => {
        sourceChart.hideLoading()
        this.sourceTimer = setTimeout(() => {
          this.fetchSourceType()
        }, 1000 * 60 * 5)
      })
    }
  }
}
</script>

<style lang="less">
#alert-total-severity-chart {
  height: 130px;
  width: 440px;
  margin: 0 0 10px 14px;
}
#alert-total-source-chart {
  height: 300px;
  width: 440px;
  margin-left: 5px;
}
</style>
