<template>
  <div class="topology-menu">
    <div class="video-btn-container">
      <a @click="changePlay">
        <pause-icon v-show="isPlay"></pause-icon>
        <play-icon v-show="!isPlay"></play-icon>
      </a>
    </div>
    <div class="overflow-scroll-hidden" style="height: 216px; overflow: auto;">
      <a-row :gutter="16" v-if="selectedTopologyId" style="margin: 0;">
        <a-col
          :span="6"
          v-for="(item, index) in topologyData"
          :key="item.id"
          style="margin: 16px 0 0"
        >
          <div
            :class="[
              selectedTopologyId === item.id ? (alertList[index] > 0 ? 'alert-container--active' : 'container--active') : '',
              'container',
              alertList[index] > 0 ? 'alert' : ''
            ]"
            @click="changeTopology(item, index)"
          >
            <a-tooltip>
              <template slot="title">
                <p style="margin: 0 0 4px">业务名称：{{ item.name }}</p>
              </template>
              <p class="container__title">{{ item.name }}</p>
            </a-tooltip>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { getServiceList, getServiceAlertCount } from '@/api/service'
import PauseIcon from '@/components/icon/PauseIcon'
import PlayIcon from '@/components/icon/PlayIcon'

export default {
  name: 'TopologyMenu',
  components: {
    PauseIcon,
    PlayIcon
  },
  data () {
    return {
      isPlay: false,
      params: {
        page: 1,
        page_size: 99,
        completed: true
      },
      total: 0,
      topologyData: [],
      selectedTopologyId: '',
      timer: null,
      index: 0,
      alertList: [],
      refreshTimer: null
    }
  },
  mounted () {
    this.fetch()
  },
  destroyed () {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer)
      this.refreshTimer = null
    }
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    fetch () {
      getServiceList(this.params).then(res => {
        const data = res.data
        this.total = data.total
        this.topologyData.push(...data.data)
        if (this.total > this.params.page * this.params.page_size) {
          this.params.page += 1
          this.fetch()
        }
        if (this.topologyData.length) {
          this.selectedTopologyId = this.topologyData[this.index].id
          this.$emit('currentTopology', this.topologyData[this.index])
        }
        Promise.all(this.fetchAlert()).then(() => {
          this.refreshTimer = setTimeout(() => {
            this.$emit('refresh')
            this.$emit('currentTopology', '')
            this.selectedTopologyId = ''
            this.topologyData = []
            this.total = 0
            this.index = 0
            this.alertList = []
            this.fetch()
          }, 1000 * 60 * 5)
        })
      })
    },
    fetchAlert () {
      const promiseList = []
      this.alertList = []
      for (let i = 0; i < this.total; i++) {
        const id = this.topologyData[i].id
        const p = getServiceAlertCount(id, {
          count_of: 'severity',
          recovered: false
        }).then(res => {
          let total = 0
          res.data.data.forEach(item => {
            total += item.value
          })
          this.alertList.push(total)
        }).catch(() => {})
        promiseList.push(p)
      }
      return promiseList
    },
    changeTopology (item, index) {
      if (index !== this.index) {
        this.selectedTopologyId = item.id
        this.$emit('currentTopology', item)
        this.index = index
        if (this.timer) clearInterval(this.timer)
        if (this.isPlay) {
          this.timer = setInterval(() => {
            if (this.index < this.topologyData.length - 1) this.index++
            else this.index = 0
            this.selectedTopologyId = this.topologyData[this.index].id
            this.$emit('currentTopology', this.topologyData[this.index])
          }, 1000 * 6)
        }
      }
    },
    changePlay () {
      this.isPlay = !this.isPlay
      if (this.timer) clearInterval(this.timer)
      if (this.isPlay) {
        this.timer = setInterval(() => {
          if (this.index < this.topologyData.length - 1) this.index++
          else this.index = 0
          this.selectedTopologyId = this.topologyData[this.index].id
          this.$emit('currentTopology', this.topologyData[this.index])
        }, 1000 * 6)
      }
    }
  }
}
</script>

<style lang="less">
.topology-menu {
  height: 225px;
  padding: 0 16px 16px;
  position: relative;

  .video-btn-container {
    position: absolute;
    height: 42px;
    width: 32px;
    top: -39px;
    left: 34px;
    font-size: 24px;
    line-height: 42px;
    z-index: 999;
    a {
      color: #4c9df4c5;
    }
  }

  .container {
    height: 56px;
    background: linear-gradient(to left, #22b2f5, #22b2f5) left top no-repeat,
      linear-gradient(to bottom, #22b2f5, #22b2f5) left top no-repeat,
      linear-gradient(to left, #22b2f5, #22b2f5) right top no-repeat,
      linear-gradient(to bottom, #22b2f5, #22b2f5) right top no-repeat,
      linear-gradient(to left, #22b2f5, #22b2f5) left bottom no-repeat,
      linear-gradient(to bottom, #22b2f5, #22b2f5) left bottom no-repeat,
      linear-gradient(to left, #22b2f5, #22b2f5) right bottom no-repeat,
      linear-gradient(to left, #22b2f5, #22b2f5) right bottom no-repeat;
    background-size: 2px 14px, 20px 2px, 2px 14px, 20px 2px;
    // background-color: rgba(0, 42, 78, 0.33);
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px #092e6a33, inset 0px 0px 20px #08427542;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    transition: all 0.6s;
    .container__title {
      text-align: center;
      line-height: 25px;
      padding: 0 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .alert {
    background: linear-gradient(to left, #fd5a72, #fd5a72) left top no-repeat,
      linear-gradient(to bottom, #fd5a72, #fd5a72) left top no-repeat,
      linear-gradient(to left, #fd5a72, #fd5a72) right top no-repeat,
      linear-gradient(to bottom, #fd5a72, #fd5a72) right top no-repeat,
      linear-gradient(to left, #fd5a72, #fd5a72) left bottom no-repeat,
      linear-gradient(to bottom, #fd5a72, #e45430) left bottom no-repeat,
      linear-gradient(to left, #fd5a72, #fd5a72) right bottom no-repeat,
      linear-gradient(to left, #fd5a72, #fd5a72) right bottom no-repeat;
      background-size: 2px 14px, 20px 2px, 2px 14px, 20px 2px;
      box-shadow: 0px 0px 5px #fd5a7233, inset 0px 0px 20px #fd5a7242;
  }
  .container--active {
    background-color: rgba(0, 149, 255, 0.1);
    color: #22b2f5;
  }
  .alert-container--active {
    background-color: #fd5a7230;
    color: #ff97a7;
  }
}
</style>
