<template>
  <div id="usage-cpu-chart" style="width: 450px; height: 224px"></div>
</template>

<script>
import { subTitle } from '@/utils'
import { getOSTop } from '@/api/os'

let cpuChart = null

export default {
  name: 'CpuUsage',
  data () {
    return {
      data: [],
      option: {},
      maxData: [100, 100, 100, 100, 100],
      xName: [],
      timer: null
    }
  },
  mounted () {
    this.initChart()
  },
  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    if (cpuChart) {
      cpuChart.clear()
      cpuChart = null
    }
  },
  methods: {
    initChart () {
      if (cpuChart !== null) cpuChart.dispose()
      cpuChart = this.$echarts.init(document.getElementById('usage-cpu-chart'))
      this.option = {
        grid: {
          top: 5,
          left: 20,
          right: 20,
          bottom: -16,
          containLabel: true
        },
        tooltip: {
          show: true,
          formatter: params => {
            return params.name
          }
        },
        xAxis: [
          {
            show: false,
            min: 0,
            max: 100
          }
        ],
        yAxis: [
          {
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            axisLabel: {
              margin: 10,
              color: '#aabed7',
              fontSize: '12',
              formatter: params => {
                return subTitle(params, 15)
              }
            },
            data: this.xName,
            triggerEvent: true
          },
          {
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            type: 'category',
            axisLabel: {
              margin: 20,
              color: '#83c9fe',
              fontSize: '14',
              formatter: params => {
                return params + '%'
              }
            },
            data: this.data
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: 18,
            itemStyle: {
              color: params => {
                if (params.value >= 85) {
                  return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(255,100,121,.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,100,121,1)'
                    }
                  ])
                } else if (params.value >= 60) {
                  return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(0,107,255,0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,107,255,1)'
                    }
                  ])
                } else {
                  return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(36,188,255,.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(36,188,255,1)'
                    }
                  ])
                }
              }
            },
            data: this.data,
            z: 0
          },
          {
            type: 'pictorialBar',
            itemStyle: {
              color: '#011140'
            },
            symbolRepeat: 11,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [4, 20],
            data: this.data,
            z: 1
          },
          {
            type: 'bar',
            barGap: '-117%',
            data: this.maxData,
            barWidth: 24,
            itemStyle: {
              color: 'transparent',
              borderColor: '#0e4160',
              borderWidth: 1
            },
            z: 2
          }
        ]
      }
      cpuChart.setOption(this.option)
      this.fetch()
    },
    fetch () {
      cpuChart.showLoading({
        text: '加载中...',
        maskColor: '#051733',
        zlevel: 999,
        color: '#314daa',
        textColor: '#a3b7d9'
      })
      this.xName = []
      getOSTop({ top_of: 'cpu', limit: 5 }).then(res => {
        this.data = res.data.data
        this.data.forEach(element => {
          this.xName.push(element.name)
        })
        cpuChart.setOption({
          yAxis: [{ data: this.xName }, { data: this.data }],
          series: [{ data: this.data }, { data: this.data }]
        })
      }).finally(() => {
        cpuChart.hideLoading()
        this.timer = setTimeout(() => {
          this.fetch()
        }, 1000 * 60 * 5)
      })
    }
  }
}
</script>
